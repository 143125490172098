










import BaseSelect from "@/components/BaseSelect.vue";
import { MediaAlbum } from "@/models/media-album.model";
import MediaAlbumService from "@/services/MediaAlbumService";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseSelect },
})
export default class MediaAlbumSelect extends Vue {
  @VModel({ type: Array })
  data!: MediaAlbum[];

  @Prop()
  multiple!: boolean;

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop({
    default: false,
  })
  useId!: boolean;

  reduce(x) {
    if (this.useId) {
      return x.id;
    }
    return x;
  }

  async fetchMediaAlbums(): Promise<MediaAlbum[]> {
    return MediaAlbumService.fetchAllUnpaginated();
  }
}
