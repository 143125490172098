

























import { MediaAlbum } from "@/models/media-album.model";
import Vue from "vue";
import Component from "vue-class-component";
import Vuetable from "vuetable-2";
import { namespace } from "vuex-class";
import * as _ from "lodash";
import LanguageTabs from "../LanguageTabs.vue";
import ImageUpload from "../ImageUpload.vue";
import MediaAlbumSelect from "./MediaAlbumSelect.vue";

const mediaNamespace = namespace("MediaAlbums");

@Component({
  components: { Vuetable, LanguageTabs, ImageUpload, MediaAlbumSelect },
})
export default class MediaAlbumDetail extends Vue {
  isLoading = false;
  searchString = "";
  perPage = 5000;
  language = this.$i18n.locale;

  @mediaNamespace.Getter("current")
  media!: MediaAlbum;

  @mediaNamespace.Action("fetchById")
  fetchMedia!: (id: string) => Promise<MediaAlbum>;

  @mediaNamespace.Action("create")
  create!: (media: MediaAlbum) => Promise<MediaAlbum>;

  @mediaNamespace.Action("updateById")
  update!: (media: MediaAlbum) => Promise<MediaAlbum>;

  get locale() {
    return this.$root.$i18n.locale;
  }

  get isCreate() {
    return this.$route.name === "media-album-create";
  }

  get mediaId() {
    return this.$route.params.id;
  }

  async doSave() {
    this.isLoading = true;
    if (this.isCreate) {
      const newMedia = await this.create(this.media);
      this.$router.push({
        name: "media-album-detail",
        params: { id: newMedia.id },
      });
    } else {
      await this.update(this.media);
    }
    this.isLoading = false;
  }

  async doFetchMedia() {
    this.isLoading = true;
    await this.fetchMedia(this.mediaId);
    this.isLoading = false;
  }

  async created() {
    if (!this.isCreate) {
      await this.doFetchMedia();
    }
  }
}
