









import Vue from "vue";
import Component from "vue-class-component";
import { VModel, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const languagesNamespace = namespace("Languages");

@Component({ components: {} })
export default class LanguageTabs extends Vue {
  languageIndex = 0;

  @VModel()
  language!: string;

  @Watch("languageIndex", { immediate: true })
  onLanguageIndexChange() {
    this.language = this.languages[this.languageIndex];
  }

  @languagesNamespace.Getter("all")
  languages!: string[];
}
